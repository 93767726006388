import * as React from "react";
import strings from "./strings.json";

// @ts-expect-error
const StringsContext = React.createContext();

function useContextValue(lang) {
  return React.useMemo(() => {
    return {
      ...strings.defaults,
      ...strings[lang],
    };
  }, [lang]);
}

export function StringsProvider({ lang, children }) {
  const contextValue = useContextValue(lang);

  return (
    <StringsContext.Provider value={contextValue}>
      {children}
    </StringsContext.Provider>
  );
}

export const useStrings = () => React.useContext(StringsContext);
