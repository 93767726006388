import React from "react";

const Range = React.forwardRef(
  ({ id, placeholder, ariaLabel, className = "", ...rest }, ref) => (
    <input
      ref={ref}
      id={id}
      type="range"
      aria-describedby={ariaLabel}
      className={`form-range
      appearance-none
      w-60
      h-0.5
      p-0
      input-colors
      focus:outline-none focus:ring-0 focus:shadow-none ${className}`}
      {...rest}
    ></input>
  )
);

export default Range;
