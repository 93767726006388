import * as React from 'react'
import { useTheme } from '../../hooks/use-theme'
import { Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    fontFamily: 'OpenSans',
    fontSize: 10,
  },
})

export const Resume = ({ children, style }) => {
  const theme = useTheme()

  return (
    <Text style={[styles.container, { color: theme.colors.text }, style]}>
      {children}
    </Text>
  )
}
