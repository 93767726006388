import { useTheme } from "../../hooks/use-theme";
import { Text, StyleSheet, View, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  name: {
    fontFamily: "OpenSans",
    fontSize: 10,
  },
  username: {
    fontFamily: "OpenSans",
    fontSize: 8,
    marginTop: 2,
  },
});

export function SocialMedia({ name = "", profileUrl = "", ...props }) {
  const theme = useTheme();
  let username;
  if (profileUrl.includes("/")) {
    username = `@${profileUrl.split("/").splice(-1)}`;
  } else {
    username = profileUrl;
  }

  return (
    <View {...props}>
      <Text style={styles.name}>{name}</Text>
      <Link src={profileUrl}>
        <Text style={[styles.username, { color: theme.colors.text }]}>
          {username}
        </Text>
      </Link>
    </View>
  );
}
