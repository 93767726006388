import { useTheme } from "../../hooks/use-theme";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Icon } from "../icon";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontFamily: "Quicksand",
    fontSize: 8,
    marginLeft: 4,
  },
});

export function IconText({ style, text, iconName }) {
  const theme = useTheme();

  return (
    <View style={[styles.container, style]}>
      <Icon size={10} name={iconName} />
      <Text style={[styles.text, { color: theme.colors.text }]}>{text}</Text>
    </View>
  );
}
