import React from "react";
import PDFViewer from "./PDFViewer";
import { App as Resume} from "../ResumeV2";

const Result = ({ data }) => {
  return (
    <div className="right-side">
      <div class="block p-6 max-w-xxl" style={{ background: "#ededed" }}>
        <PDFViewer>
          <Resume data={data} />
        </PDFViewer>
      </div>
    </div>
  );
};

export default React.memo(Result);
