import React, { useState, useCallback, useEffect } from "react";
import { pdfjs, Document, Page, Outline } from "react-pdf";
import { pdf } from "@react-pdf/renderer";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ children }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = useCallback((document) => {
    const { numPages: nextNumPages } = document;
    setNumPages(nextNumPages);
    setPageNumber(pageNumber);
  }, [pageNumber]);

  const onItemClick = useCallback(
    ({ pageNumber: nextPageNumber }) => setPageNumber(nextPageNumber),
    []
  );

  const changePage = useCallback(
    (offset) =>
      setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    []
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  useEffect(() => {
    const child = React.Children.only(children);

    pdf(child)
      .toBlob()
      .then((blob) => {
        setPdfUrl(URL.createObjectURL(blob));
      });
  }, [children]);

  return (
    <Document
      file={pdfUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      onItemClick={onItemClick}
    >
      <Outline className="custom-classname-outline" onItemClick={onItemClick} />
      <Page renderMode="svg" pageNumber={pageNumber} />
      {numPages > 1 && (
        <div className="flex justify-center p-1">
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded mr-2 ${pageNumber <= 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={pageNumber <= 1}
            onClick={previousPage}
            type="button"
          >
            Previous
          </button>
          <span>
            {`Page ${pageNumber || (numPages ? 1 : "--")} of ${
              numPages || "--"
            }`}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            type="button"
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded ml-2 ${pageNumber >= numPages ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            Next
          </button>
        </div>
      )}
    </Document>
  );
};

export default PDFViewer;
