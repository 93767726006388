import * as React from "react";
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import { WorkPost } from "../work-post";
import { useTheme } from "../../hooks/use-theme";
import { IconText } from "../icon-text";
import { DateRange } from "../date-range";

const styles = StyleSheet.create({
  workPlace: {
    fontSize: 10,
    fontFamily: "Quicksand",
    fontWeight: "bold",
  },
  description: {
    fontSize: 8,
    fontFamily: "OpenSans",
    marginTop: 4,
  },
});
const WorkPlace = ({ children, url, style }) => {
  const theme = useTheme();

  const commonStyle = [
    styles.workPlace,
    { color: theme.colors.primary },
    style,
  ];

  if (url) {
    return (
      <Link src={url}>
        <Text style={commonStyle}>{children}</Text>
      </Link>
    );
  }

  return <Text style={commonStyle}>{children}</Text>;
};

export const EducationPost = ({
  style,
  title,
  almaMater,
  startAt,
  endAt,
  location,
  children,
  companyName,
  companyUrl,
  description,
}) => {
  const theme = useTheme();
  return (
    <View style={style}>
      <WorkPost.Title>{title}</WorkPost.Title>
      {companyName ? (
        <WorkPlace url={companyUrl}>{companyName}</WorkPlace>
      ) : null}
      <WorkPost.WorkPlace style={{ marginTop: 4, marginBottom: 4 }}>
        {almaMater}
      </WorkPost.WorkPlace>
      <DateRange style={{ marginBottom: 4 }} startAt={startAt} endAt={endAt} />
      <IconText
        style={{ marginBottom: 4 }}
        iconName="location"
        text={location}
      />
      {description && (
        <Text style={[styles.description, { color: theme.colors.text }]}>
          {description}
        </Text>
      )}
      <View style={{ marginTop: 8 }}>{children}</View>
    </View>
  );
};
