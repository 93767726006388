import * as React from "react";
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import { useTheme } from "../../hooks/use-theme";
import { IconText } from "../icon-text";

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    fontFamily: "Quicksand",
    fontWeight: "bold",
  },
  metaInfoContainer: {
    flexDirection: "row",
    marginTop: 4,
    alignItems: "left",
  },
  projectPlace: {
    fontSize: 10,
    fontFamily: "Quicksand",
    fontWeight: "bold",
  },
  description: {
    fontSize: 8,
    fontFamily: "OpenSans",
    marginTop: 4,
  },
  content: {
    marginTop: 10,
  },
  link: {
    marginRight: 16,
    textDecoration: "none",
  },
});

const Title = ({ children, style }) => {
  return <Text style={[styles.title, style]}>{children}</Text>;
};

const ProjectPlace = ({ children, url, style }) => {
  const theme = useTheme();

  const commonStyle = [
    styles.projectPlace,
    { color: theme.colors.primary },
    style,
  ];

  if (url) {
    return (
      <Link src={url}>
        <Text style={commonStyle}>{children}</Text>
      </Link>
    );
  }

  return <Text style={commonStyle}>{children}</Text>;
};

export const ProjectPost = ({
  style,
  title,
  github,
  link,
  projectDescription,
  children,
}) => {
  const theme = useTheme();

  return (
    <View style={style}>
      <Title>{title}</Title>
      <View style={styles.metaInfoContainer}>
        <Link src={github} style={styles.link}>
          <IconText text={"Source code"} iconName="github" />
        </Link>

        <Link
          src={link}
          style={styles.link}
        >
          <IconText text={"Showcase website"} iconName="link" />
        </Link>
      </View>
      {projectDescription && (
        <Text style={[styles.description, { color: theme.colors.text }]}>
          {projectDescription}
        </Text>
      )}
      {React.Children.count(children) > 0 ? (
        <View style={styles.content}>{children}</View>
      ) : null}
    </View>
  );
};

ProjectPost.Title = Title;

ProjectPost.ProjectPlace = ProjectPlace;
