import React from "react";

const Textarea = React.forwardRef(
  (
    { id, placeholder, type, ariaLabel, isRight = false, children, ...rest },
    ref
  ) => (
    <textarea
      type={type}
      id={id}
      ref={ref}
      className="form-control
        input-colors
        textarea-about
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:border-blue-600 focus:outline-none"
      aria-describedby={ariaLabel}
      {...rest}
    >
      {children}
    </textarea>
  )
);

export default Textarea;
