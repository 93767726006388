import * as React from 'react'

// @ts-expect-error
const ThemeContext = React.createContext()

export function ThemeProvider({ children, theme }) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useTheme = () => React.useContext(ThemeContext)
