import { Document, View, StyleSheet, Page } from "@react-pdf/renderer";
import { ThemeProvider, StringsProvider, useStrings } from "./hooks";
import {
  Heading,
  Section,
  ListItem,
  WorkPost,
  ProjectPost,
  Resume,
  EducationPost,
  Language,
  Insight,
  SocialMedia,
  Watermark,
} from "./ui";
import { createTheme } from "./theme";

const styles = StyleSheet.create({
  page: {
    paddingTop: 48,
    paddingHorizontal: 50,
  },
  row: {
    flexDirection: "row",
  },
  leftColumn: {
    flexGrow: 1,
    marginRight: 16,
    width: "55%",
  },
  rightColumn: {
    flexGrow: 1,
    width: "40%",
  },
});

const languageSkills = [
  "Beginner",
  "Elementary",
  "Intermediate",
  "Advanced",
  "Proficient",
];

function AppDocument({ data, techProfile, techResume }) {
  const s = useStrings();

  return (
    <Document
      title={`${techProfile.name} - ${techResume.title}`}
      author={techProfile.name}
      keywords={techResume.keywords}
    >
      <Page size="A4" style={styles.page}>
        <Watermark />
        <Heading
          title={data.fullName}
          subtitle={data.jobTitle}
          avatarUrl={techResume.avatarUrl}
          information={{
            phone: data.phone,
            email: data.email,
            website: data.website,
            location: data.address,
          }}
        />
        <View style={styles.row}>
          <View style={styles.leftColumn}>
            <Section title={s["title.projects"]} spacing={8}>
              {data.workProjects?.map((workProject) => (
                <ProjectPost
                  key={workProject.id}
                  title={workProject.projectname}
                  github={workProject.githublink}
                  link={workProject.projectwebsite}
                  projectDescription={workProject.projectdescription}
                >
                  {workProject.projectachievements
                    .split("\n")
                    .map((line, i) => (
                      <ListItem key={`${workProject.id}-line-${i}`}>
                        {line}
                      </ListItem>
                    ))}
                </ProjectPost>
              ))}
            </Section>
            <Section title={s["title.workExperience"]} spacing={8}>
              {data.workExperiences?.map((workExperience) => (
                <WorkPost
                  key={workExperience.id}
                  title={workExperience.worktitle}
                  companyName={workExperience.company}
                  companyUrl={workExperience.url}
                  location={workExperience.location}
                  startAt={workExperience.startdate}
                  endAt={workExperience.enddate}
                  description={workExperience.description}
                >
                  {workExperience.achievements.split("\n").map((line, i) => (
                    <ListItem key={`${workExperience.id}-line-${i}`}>
                      {line}
                    </ListItem>
                  ))}
                </WorkPost>
              ))}
            </Section>
          </View>
          <View style={styles.rightColumn}>
            <Section title={s["title.aboutMe"]} spacing={8}>
              <Resume>{data.bio}</Resume>
            </Section>
            <Section title={s["title.education"]} spacing={8}>
              {data.educations?.map((educationExperience) => (
                <EducationPost
                  key={educationExperience.id}
                  title={educationExperience.title}
                  companyName={educationExperience.institute}
                  companyUrl={educationExperience.url}
                  location={educationExperience.location}
                  startAt={educationExperience.startdate}
                  endAt={educationExperience.enddate}
                  description={educationExperience.description}
                >
                  {educationExperience.achievements
                    .split("\n")
                    .map((line, i) => (
                      <ListItem key={`${educationExperience.id}-line-${i}`}>
                        {line}
                      </ListItem>
                    ))}
                </EducationPost>
              ))}
            </Section>
            <Section title={s["title.lang"]} spacing={12}>
              {data.languages?.map((langSkill) => (
                <Language
                  key={langSkill.id}
                  name={langSkill.language}
                  scoreLabel={languageSkills[langSkill.level - 1]}
                  score={langSkill.level}
                />
              ))}
            </Section>
            <Section title={s["title.strengths"]} spacing={12}>
              {data.skills?.map((strength) => (
                <Insight
                  key={strength.id}
                  title={strength.skill}
                  description={strength.skilldescription}
                  iconName={strength.icon}
                />
              ))}
            </Section>
            <Section title={s["title.findMe"]} spacing={0}>
              <View style={{ flexDirection: "row" }}>
                <SocialMedia
                  name="Twitter"
                  profileUrl={data.twitter}
                  style={{ flex: 1 }}
                />
                <SocialMedia
                  name="Github"
                  profileUrl={data.github}
                  style={{ flex: 1 }}
                />
                <SocialMedia
                  name="LinkedIn"
                  profileUrl={data.linkedin}
                  style={{ flex: 1 }}
                />
              </View>
            </Section>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const initialResume = {
  id: "1234",
  published: true,
  keywords: "javascript, resume",
  title: "ersel",
  lang: "en",
  name: "ersel aker",
  primaryColor: "#000",
  aboutMe: "developer",
  createdAt: "2021-01-01",
  avatarUrl: "s",
  langSkills: [
    {
      id: "lang-1",
      name: "English",
      scoreLabel: "Fluent",
      score: 99,
    },
  ],
  strengths: [
    {
      id: "s-1",
      name: "JavaScript",
      description: "hello",
      icon: "cog",
    },
  ],
  educationExperiences: [
    {
      id: "e1",
      title: "Software Engineering",
      location: "Manchester, UK",
      lines: ["line 1", "line 2"],
      endAt: "2022-01-01",
      startAt: "2020-01-01",
      almaMater: "University of Manchester",
    },
  ],
  workExperiences: [
    {
      id: "w1",
      lines: ["line 1", "line 2"],
      startAt: "2020-01-01",
      location: "Cyprus",
      company: "CyprusCodes",
      companyUrl: "https://cypruscodes.com",
      description: "Courses",
      title: "Software Engineer",
      endAt: "2022-01-01",
    },
  ],
  techGroups: [
    {
      id: "g1",
      title: "MancJS",
      tags: ["js", "devops"],
    },
  ],
  workProjects: [
    {
      id: "wp1",
      description: "xest is a framework",
      title: "xest",
      company: "cypruscodes",
      lines: ["cli interface", "something else"],
      startAt: "2022-01-01",
      location: "Cyprus",
      endAt: "2025-01-01",
    },
  ],
};
const initialProfile = {
  id: "p1",
  website: "erselaker.com",
  location: "Cyprus / UK",
  twitter: "@ersel",
  github: "ersel",
  phone: "1234123",
  name: "ERSEL AKER",
  email: "hello@erselaker.com",
  freelanceRole: "yeah boi",
  interests: ["books", "philosophy", "startups"],
  linkedin: "ersel",
  nickname: "eazy-e",
};

export function App({
  lang = "en",
  techResume = initialResume,
  techProfile = initialProfile,
  data,
}) {
  const theme = createTheme({ primaryColor: techResume.primaryColor });

  return (
    <ThemeProvider theme={theme}>
      <StringsProvider lang={lang}>
        <AppDocument
          data={data}
          techResume={techResume}
          techProfile={techProfile}
        />
      </StringsProvider>
    </ThemeProvider>
  );
}
