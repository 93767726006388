import React from "react";

const Input = React.forwardRef(
  (
    {
      id,
      placeholder,
      type,
      ariaLabel,
      isRight = false,
      className = "",
      ...rest
    },
    ref
  ) => (
    <input
      ref={ref}
      id={id}
      type={type}
      placeholder={placeholder}
      aria-describedby={ariaLabel}
      {...rest}
      className={`form-control input-colors block ${
        isRight ? "right-input" : ""
      } w-full px-3 py-1.5 text-base font-normal transition ease-in-out m-0 focus:text-gray-700 focus:border-blue-600 focus:outline-none ${className}`}
    ></input>
  )
);

export default Input;
