import React, { useEffect, useState } from "react";
import Result from "../Result";
import Input from "../FormElements/Input";
import Range from "../FormElements/Range";
import { useForm, useFieldArray } from "react-hook-form";
import styled from "@emotion/styled";
import useInterval from "../../hooks/useInterval";
import media from "../../media";
import deepEqual from "fast-deep-equal";
import Textarea from "../FormElements/Textarea";
import useDebounce from "../../hooks/useDebounce";
import isEmpty from "lodash.isempty";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  max-height: calc(100vh - 124px);

  ${media.desktop} {
    display: initial;
    max-height: calc(100vh - 124px);
  }
`;

const CodePanel = styled.div`
  flex: 1;
  overflow: scroll;
  position: relative;

  ${media.desktop} {
    width: 100%;
    height: 100%;
  }
`;

const PDFPanel = styled.div`
  flex: 1;
  overflow: auto;
  background-color: #fff;

  ${media.desktop} {
    width: 100%;
    height: 100%;
  }
`;

const Forms = ({ cvData, setCVData }) => {
  let initialValues = { languages: [] };
  const cvDataFromStorage = localStorage.getItem("cvData");
  if (!isEmpty(cvDataFromStorage)) {
    initialValues = JSON.parse(cvDataFromStorage);
  }

  const { register, getValues, control } = useForm({
    defaultValues: initialValues,
  });
  const [tempCVData, setTempCVData] = useState({});

  const {
    fields: languages,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: "languages",
  });

  const {
    fields: workExperiences,
    append: appendWork,
    remove: removeWork,
  } = useFieldArray({
    control,
    name: "workExperiences",
  });

  const {
    fields: skills,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({
    control,
    name: "skills",
  });
  const {
    fields: educations,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    control,
    name: "educations",
  });
  const {
    fields: workProjects,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "workProjects",
  });

  const debouncedCVData = useDebounce(tempCVData, 200);

  useInterval(() => {
    const currentValues = getValues();
    if (!deepEqual(tempCVData, currentValues)) {
      setTempCVData(JSON.parse(JSON.stringify(currentValues)));
    }
  }, 200);

  useEffect(() => {
    setCVData(debouncedCVData);
    localStorage.setItem("cvData", JSON.stringify(debouncedCVData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCVData]);

  return (
    <Wrapper>
      <CodePanel>
        <div className="block p-6 max-w-xxl">
          <div className="form-group mb-3 flex">
            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs inline-block mb-2 text-title"
              >
                Full Name
              </label>
              <Input
                type="text"
                id="fullName"
                ariaLabel="first name"
                placeholder="Salih"
                {...register("fullName")}
              />
            </div>
            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs ml-3 inline-block mb-2 text-title"
              >
                Job Title
              </label>
              <Input
                type="text"
                id="jobTitle"
                ariaLabel="job title helper"
                placeholder="Junior React Developer"
                isRight
                {...register("jobTitle")}
              />
            </div>
          </div>

          <div className="form-group mb-3 flex">
            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs inline-block mb-2 text-title"
              >
                Email
              </label>
              <Input
                type="email"
                id="email"
                ariaLabel="email helper"
                placeholder="salih.cobanoglu@gmail.com"
                {...register("email")}
              />
            </div>

            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs ml-3 inline-block mb-2 text-title"
              >
                Phone
              </label>
              <Input
                type="text"
                id="phone1"
                ariaLabel="phone helper"
                placeholder="+90 533 8100901"
                isRight
                {...register("phone")}
              />
            </div>
          </div>
          <div className="form-group mb-3 flex">
            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs inline-block mb-2 text-title"
              >
                Address
              </label>
              <Input
                type="text"
                id="address"
                ariaLabel="address helper"
                placeholder="Nicosia, Cyprus"
                {...register("address")}
              />
            </div>
            <div className="w-full">
              <label
                for="exampleInputEmail1"
                className="form-label text-xs ml-3 inline-block mb-2 text-title"
              >
                Website
              </label>
              <Input
                type="text"
                id="website"
                ariaLabel="website helper"
                placeholder="https://salihcobanoglu.dev"
                isRight
                {...register("website")}
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              Languages
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex flex-col">
                {languages.map((language, index) => {
                  return (
                    <div className="flex mt-1">
                      <div className="w-full ml-9">
                        <label
                          for="exampleInputEmail1"
                          className="form-label text-xs inline-block mb-2 text-title"
                        >
                          Language
                        </label>
                        <Input
                          type="text"
                          id={`language-${index}`}
                          ariaLabel="languages"
                          placeholder="React"
                          className="input-groups"
                          {...register(`languages.${index}.language`)}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          for="exampleInputEmail1"
                          className="form-label text-xs  ml-2 block mb-2 text-title"
                        >
                          Level
                        </label>
                        <Range
                          id={`language-${index}`}
                          ariaLabel="languages"
                          max="5"
                          min="1"
                          {...register(`languages.${index}.level`)}
                        />
                      </div>
                      <div className="w-24 flex items-center mt-6 p-1 ml-2 cursor-pointer">
                        <img
                          src="assets/images/delete.png"
                          alt="delete"
                          height={20}
                          width={20}
                          onClick={() => removeLanguage(index)}
                        ></img>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              for="exampleInputEmail1"
              className="form-label text-xs mt-4 inline-block mb-2 text-title"
              onClick={() => appendLanguage({ language: "English", level: 4 })}
            >
              + Add Language
            </button>
          </div>

          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              About Me
            </label>
            <Textarea id="bio" {...register("bio")}>
              a short paragraph about yourself.
            </Textarea>
          </div>

          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              Work Experience
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex flex-col">
                {workExperiences.map((work, index) => {
                  return (
                    <>
                      <div className="flex mt-3">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Role
                          </label>
                          <Input
                            type="text"
                            id={`work-${index}-title`}
                            ariaLabel="Software Developer"
                            placeholder="Software Developer"
                            className="input-groups"
                            {...register(`workExperiences.${index}.worktitle`)}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Location
                          </label>
                          <Input
                            type="text"
                            id={`work-${index}-location`}
                            ariaLabel="Nicosia, North Cyprus"
                            placeholder="Nicosia, North Cyprus"
                            className="input-groups"
                            {...register(`workExperiences.${index}.location`)}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Company Name
                          </label>
                          <Input
                            type="text"
                            id={`work-${index}-company-name`}
                            ariaLabel="CyprusCodes"
                            placeholder="CyprusCodes"
                            className="input-groups"
                            {...register(`workExperiences.${index}.company`)}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Company Url
                          </label>
                          <Input
                            type="text"
                            id={`work-${index}-url`}
                            ariaLabel="www.cypruscodes.com"
                            placeholder="www.cypruscodes.com"
                            className="input-groups"
                            {...register(`workExperiences.${index}.url`)}
                          />
                        </div>
                      </div>
                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Start Date
                          </label>
                          <Input
                            type="date"
                            id={`work-${index}-startdate`}
                            ariaLabel="Software Developer"
                            placeholder="Software Developer"
                            className="input-groups"
                            {...register(`workExperiences.${index}.startdate`)}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            End Date
                          </label>
                          <Input
                            type="date"
                            id={`work-${index}-enddate`}
                            ariaLabel="CyprusCodes"
                            placeholder="CyprusCodes"
                            className="input-groups"
                            {...register(`workExperiences.${index}.enddate`)}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Company description
                          </label>
                          <Textarea
                            id={`work-${index}-description`}
                            {...register(
                              `workExperiences.${index}.description`
                            )}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-full ml-2">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Achievements
                          </label>
                          <Textarea
                            id={`work-${index}-achievements`}
                            {...register(
                              `workExperiences.${index}.achievements`
                            )}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-24 flex items-center mt-6 p-1 ml-4 cursor-pointer">
                          <img
                            src="assets/images/delete.png"
                            alt="delete"
                            height={20}
                            width={20}
                            onClick={() => removeWork(index)}
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <button
              for="exampleInputEmail1"
              className="form-label text-xs mt-4 inline-block mb-2 text-title"
              onClick={() =>
                appendWork({
                  worktitle: "Software Developer",
                  company: "CyprusCodes",
                  location: "Nicosia, Cyprus",
                  description: "Training provider and software consultancy",
                  achievements:
                    "Implemented a REST API using Xest framework \nUpdated Xest documentation website",
                  url: "www.cypruscodes.com",
                })
              }
            >
              + Add Work Experience
            </button>
          </div>

          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              Projects
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex flex-col">
                {workProjects.map((project, index) => {
                  return (
                    <>
                      <div className="flex mt-3">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Project Name
                          </label>
                          <Input
                            type="text"
                            id={`project-${index}-projectname`}
                            ariaLabel="Software Developer"
                            placeholder="Software Developer"
                            className="input-groups"
                            {...register(`workProjects.${index}.projectname`)}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Github link
                          </label>
                          <Input
                            type="text"
                            id={`project-${index}-githublink`}
                            ariaLabel="https://github.com/CyprusCodes/xest"
                            placeholder="https://github.com/CyprusCodes/xest"
                            className="input-groups"
                            {...register(`workProjects.${index}.githublink`)}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-1/2 ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Website
                          </label>
                          <Input
                            type="text"
                            id={`project-${index}-projectwebsite`}
                            ariaLabel="https://www.npmjs.com/package/xest"
                            placeholder="https://www.npmjs.com/package/xest"
                            className="input-groups"
                            {...register(
                              `workProjects.${index}.projectwebsite`
                            )}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Project description
                          </label>
                          <Textarea
                            id={`project-${index}-projectdescription`}
                            {...register(
                              `workProjects.${index}.projectdescription`
                            )}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-full ml-2">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Projects Achievements
                          </label>
                          <Textarea
                            id={`project-${index}-projectachievements`}
                            {...register(
                              `workProjects.${index}.projectachievements`
                            )}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-24 flex items-center mt-6 p-1 ml-4 cursor-pointer">
                          <img
                            src="assets/images/delete.png"
                            alt="delete"
                            height={20}
                            width={20}
                            onClick={() => removeProject(index)}
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <button
              for="exampleInputEmail1"
              className="form-label text-xs mt-4 inline-block mb-2 text-title"
              onClick={() =>
                appendProject({
                  projectname: "Xest Framework",
                  githublink: "https://github.com/CyprusCodes/xest",
                  projectdescription:
                    "a nodejs framework for building REST APIs quickly",
                  projectachievements:
                    "Implemented a REST API using Xest framework \nUpdated Xest documentation website",
                  projectwebsite: "https://www.npmjs.com/package/xest",
                })
              }
            >
              + Add Projects
            </button>
          </div>

          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              Skills
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex flex-col">
                {skills.map((skill, index) => {
                  return (
                    <>
                      {" "}
                      <div className="flex mt-1">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Langugage/Framework
                          </label>
                          <Input
                            type="text"
                            id={`skill-${index}-skill`}
                            ariaLabel="skills"
                            placeholder="React"
                            className="input-groups"
                            {...register(`skills.${index}.skill`)}
                          />
                        </div>
                      </div>
                      <div className="flex mt-1">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            description
                          </label>
                          <Textarea
                            id={`skill-${index}-skilldescription`}
                            {...register(`skills.${index}.skilldescription`)}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>
                        <div className="w-24 flex items-center mt-6 p-1 ml-2 cursor-pointer">
                          <img
                            src="assets/images/delete.png"
                            alt="delete"
                            height={20}
                            width={20}
                            onClick={() => removeSkill(index)}
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <button
              for="exampleInputEmail1"
              className="form-label text-xs mt-4 inline-block mb-2 text-title"
              onClick={() =>
                appendSkill({
                  skill: "React",
                  skilldescription:
                    "Powered by Internet, Online Courses, Youtube, Blogs, etc",
                  icon: "cog",
                })
              }
            >
              + Add Skill
            </button>
          </div>

          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 text-title"
            >
              Education
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex flex-col">
                {educations.map((education, index) => {
                  return (
                    <>
                      <div className="flex mt-3">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Field
                          </label>
                          <Input
                            type="text"
                            id={`education-${index}-title`}
                            ariaLabel="Software Developer"
                            placeholder="Software Developer"
                            className="input-groups"
                            {...register(`educations.${index}.title`)}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Location
                          </label>
                          <Input
                            type="text"
                            id={`education-${index}-location`}
                            ariaLabel="Nicosia, North Cyprus"
                            placeholder="Nicosia, North Cyprus"
                            className="input-groups"
                            {...register(`educations.${index}.location`)}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Institute Name
                          </label>
                          <Input
                            type="text"
                            id={`education-${index}-company-name`}
                            ariaLabel="CyprusCodes"
                            placeholder="CyprusCodes"
                            className="input-groups"
                            {...register(`educations.${index}.institute`)}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Institute Url
                          </label>
                          <Input
                            type="text"
                            id={`education-${index}-url`}
                            ariaLabel="www.cypruscodes.com"
                            placeholder="www.cypruscodes.com"
                            className="input-groups"
                            {...register(`educations.${index}.url`)}
                          />
                        </div>
                      </div>
                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            Start Date
                          </label>
                          <Input
                            type="date"
                            id={`education-${index}-startdate`}
                            ariaLabel="Software Developer"
                            placeholder="Software Developer"
                            className="input-groups"
                            {...register(`educations.${index}.startdate`)}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs block mb-2 text-title"
                          >
                            End Date
                          </label>
                          <Input
                            type="date"
                            id={`education-${index}-enddate`}
                            ariaLabel="CyprusCodes"
                            placeholder="CyprusCodes"
                            className="input-groups"
                            {...register(`educations.${index}.enddate`)}
                          />
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <div className="w-full ml-9">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Institute Description
                          </label>
                          <Textarea
                            id={`education-${index}-description`}
                            {...register(`educations.${index}.description`)}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-full ml-2">
                          <label
                            for="exampleInputEmail1"
                            className="form-label text-xs inline-block mb-2 text-title"
                          >
                            Achievements
                          </label>
                          <Textarea
                            id={`education-${index}-achievements`}
                            {...register(`educations.${index}.achievements`)}
                          >
                            a short paragraph about yourself.
                          </Textarea>
                        </div>

                        <div className="w-24 flex items-center mt-6 p-1 ml-4 cursor-pointer">
                          <img
                            src="assets/images/delete.png"
                            alt="delete"
                            height={20}
                            width={20}
                            onClick={() => removeEducation(index)}
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <button
              for="exampleInputEmail1"
              className="form-label text-xs mt-4 inline-block mb-2 text-title"
              onClick={() =>
                appendEducation({
                  title: "Software Engineering",
                  institute: "CyprusCodes",
                  location: "Cyprus",
                  description:
                    "a 7 month full-stack software development bootcamp",
                  achievements: "learned JavaScript, Node.js, React, and MySQL \nbuilt 12 projects",
                  url: "https://www.cypruscodes.com",
                })
              }
            >
              + Add Education
            </button>
          </div>
          <div className="form-group mb-3">
            <label
              for="exampleInputEmail1"
              className="form-label text-xs inline-block mb-2 mt-3 text-title"
            >
              Social
            </label>
            <div className="w-full box-border">
              <div className="form-group mb-3 flex">
                <div className="w-full ml-9">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-xs inline-block mb-2 text-title"
                  >
                    Github
                  </label>
                  <Input
                    type="text"
                    id={`github`}
                    ariaLabel="github"
                    placeholder="github.com/ersel"
                    className="input-groups"
                    {...register("github")}
                  />
                </div>
                <div className="w-full">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-xs inline-block mb-2 text-title"
                  >
                    LinkedIn
                  </label>
                  <Input
                    type="text"
                    id={`linkedin`}
                    ariaLabel="linkedin"
                    placeholder="linkedin.com/ersel"
                    className="input-groups px-3 py-1.5"
                    {...register("linkedin")}
                  />
                </div>

                <div className="w-full">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-xs inline-block mb-2 text-title"
                  >
                    Twitter
                  </label>
                  <Input
                    type="text"
                    id={`twitter`}
                    ariaLabel="twitter"
                    placeholder="twitter.com/ersel"
                    className="input-groups px-3 py-1.5"
                    {...register("twitter")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CodePanel>
      <PDFPanel>
        <Result data={cvData} />
      </PDFPanel>
    </Wrapper>
  );
};

export default Forms;
