/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import ResumeV1 from "../ResumeV1";
import { App as ResumeV2 } from "../ResumeV2";
import kebabCase from "lodash.kebabcase";
import downloadObjectAsJson from "./downloadJSON";
import isEmpty from "lodash.isempty";
import { useDropzone } from "react-dropzone";

const Header = ({ cvData }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      const file = files[0];
      file.text().then((data) => {
        window.localStorage.setItem("cvData", data);
        window.location.reload();
      });
    },
    onDropRejected: (data) => {
      alert(data[0].errors[0].message);
    },
    accept: "application/json",
    maxFiles: 1,
    multiple: false,
  });

  return (
    <nav
      className="
            w-full
            py-6
            header-color
            navbar navbar-expand-lg navbar-light
            "
    >
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <div
          className="collapse navbar-collapse flex-grow items-center"
          id="navbarSupportedContent"
        >
          <a
            className="
                  flex
                  items-center
                  ml-12
                "
            href="#"
          >
            <img
              src="assets/images/cclogo.png"
              className="img-logo"
              alt=""
              loading="lazy"
            />
          </a>
        </div>
        <div className="flex">
          <div
            className="mr-4 mt-4"
            {...getRootProps({ className: "mr-4 mt-4" })}
          >
            <input {...getInputProps()} />
            <a
              className=""
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="assets/images/save-btn.png"
                className="save-btn"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          <div className="mr-4 mt-4">
            <a
              className=""
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                let initialValues = {};
                const cvDataFromStorage = localStorage.getItem("cvData");
                if (!isEmpty(cvDataFromStorage)) {
                  initialValues = cvDataFromStorage;
                }
                downloadObjectAsJson(initialValues, "my-cv.json");
              }}
            >
              <img
                src="assets/images/json-file.png"
                className="save-btn"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          <div className="mt-4">
            <PDFDownloadLink
              document={<ResumeV2 data={cvData} />}
              fileName={`${kebabCase(cvData.fullName)}-cv.pdf`}
            >
              {({ loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <img
                    src="assets/images/pdf.png"
                    className="save-btn"
                    alt=""
                    loading="lazy"
                  />
                )
              }
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
