import "./App.css";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <>
      <Dashboard />
    </>
  );
}

export default App;
