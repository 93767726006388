import React, { useState } from "react";
import Header from "../../components/Header";
import Forms from "../../components/Forms";

const Dashboard = () => {
  const [cvData, setCVData] = useState({ skills: [] });

  return (
    <>
      <Header cvData={cvData} />
      <Forms setCVData={setCVData} cvData={cvData} />
    </>
  );
};

export default Dashboard;
