import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { useStrings } from "../../hooks/use-strings";
import { IconText } from "../icon-text";

export function formatDate(dateString, format = "LL/yyyy") {
  const formatString = "yyyy-MM-dd";
  const referenceDate = new Date();

  let dateFormatted = "";
  try {
    dateFormatted = dateFnsFormat(
      dateFnsParse(dateString, formatString, referenceDate),
      format
    );
  } catch (e) {
    console.log(e);
  }

  return dateFormatted;
}

export function DateRange({ startAt, endAt, style }) {
  const s = useStrings();

  const dateRangeText = [
    formatDate(startAt),
    endAt ? formatDate(endAt) : s["placeholder.endAt"],
  ].join(" - ");

  return <IconText style={style} text={dateRangeText} iconName="calendar" />;
}
